exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-can-a-pandemic-finally-push-the-diamond-trade-into-the-digital-age-tsx": () => import("./../../../src/pages/blog/can-a-pandemic-finally-push-the-diamond-trade-into-the-digital-age.tsx" /* webpackChunkName: "component---src-pages-blog-can-a-pandemic-finally-push-the-diamond-trade-into-the-digital-age-tsx" */),
  "component---src-pages-blog-can-ai-really-help-improve-the-diamond-industry-tsx": () => import("./../../../src/pages/blog/can-ai-really-help-improve-the-diamond-industry.tsx" /* webpackChunkName: "component---src-pages-blog-can-ai-really-help-improve-the-diamond-industry-tsx" */),
  "component---src-pages-blog-diamond-industry-news-the-truth-about-origin-and-traceability-tsx": () => import("./../../../src/pages/blog/diamond-industry-news-the-truth-about-origin-and-traceability.tsx" /* webpackChunkName: "component---src-pages-blog-diamond-industry-news-the-truth-about-origin-and-traceability-tsx" */),
  "component---src-pages-blog-diamond-industry-news-updates-of-diamond-auctions-and-tenders-2024-tsx": () => import("./../../../src/pages/blog/diamond-industry-news-updates-of-diamond-auctions-and-tenders-2024.tsx" /* webpackChunkName: "component---src-pages-blog-diamond-industry-news-updates-of-diamond-auctions-and-tenders-2024-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-the-new-age-of-diamond-heists-cyber-security-in-the-diamond-industry-tsx": () => import("./../../../src/pages/blog/the-new-age-of-diamond-heists-cyber-security-in-the-diamond-industry.tsx" /* webpackChunkName: "component---src-pages-blog-the-new-age-of-diamond-heists-cyber-security-in-the-diamond-industry-tsx" */),
  "component---src-pages-blog-time-diamond-trade-to-think-beyond-covid-19-long-term-digital-solutions-tsx": () => import("./../../../src/pages/blog/time-diamond-trade-to-think-beyond-covid-19-long-term-digital-solutions.tsx" /* webpackChunkName: "component---src-pages-blog-time-diamond-trade-to-think-beyond-covid-19-long-term-digital-solutions-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-free-demo-tsx": () => import("./../../../src/pages/free-demo.tsx" /* webpackChunkName: "component---src-pages-free-demo-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-homepage-tsx": () => import("./../../../src/pages/homepage.tsx" /* webpackChunkName: "component---src-pages-homepage-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

